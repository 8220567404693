/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const IconMoney = ({ className }: Props): JSX.Element => {
  return (
    <img
      className={`absolute w-[37px] h-10 top-0 left-0 ${className}`}
      alt="Icon money"
      src="https://c.animaapp.com/x3n6vTjU/img/icon-money-1.svg"
    />
  );
};
