/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Logo = ({ className }: Props): JSX.Element => {
  return (
    <div className={`relative w-[135px] h-[29px] ${className}`}>
      <div className="absolute -top-7 -left-px [-webkit-text-stroke:1px_#000000] [font-family:'Radio_Canada',Helvetica] font-bold text-[#0000001a] text-5xl tracking-[0.96px] leading-[83.5px] whitespace-nowrap">
        Tbm Homes
      </div>
    </div>
  );
};
