import React from "react";
import { IconBook } from "../../components/IconBook";
import { IconMoney } from "../../components/IconMoney";
import { IconSell } from "../../components/IconSell";
import { Logo } from "../../components/Logo";

export const RealEsInvestReal = (): JSX.Element => {
  return (
    <div className="bg-transparent flex flex-row justify-center w-full">
      <div className="w-[1440px] h-[3629px] relative">
        <footer className="absolute w-[1440px] h-[63px] top-[3566px] left-0 bg-[#0090c0]">
          <div className="relative w-[1440px] h-[63px] bg-[#007ba3]">
            <p className="absolute top-[19px] left-[612px] [font-family:'Poppins',Helvetica] font-medium text-white text-[13px] tracking-[0.26px] leading-[22.6px] whitespace-nowrap">
              (c) Copyright 2007-2024 I TBM Homes
            </p>
          </div>
        </footer>
        <div className="absolute w-[1440px] h-[1142px] top-[2424px] left-0 bg-white">
          <div className="relative h-[1142px]">
            <img
              className="absolute w-[783px] h-[618px] top-0 left-[657px]"
              alt="Vector"
              src="https://c.animaapp.com/x3n6vTjU/img/vector-1.svg"
            />
            <div className="absolute w-[642px] h-[122px] top-[337px] left-[763px] bg-[#ffffff99] rounded shadow-shadow-small" />
            <div id="Contact" className="absolute top-[97px] left-[135px] [font-family:'Poppins',Helvetica] font-semibold text-[#343434] text-[57px] tracking-[1.14px] leading-[68.4px] whitespace-nowrap">
              Contact Us
            </div>
            <p className="absolute w-[570px] top-[184px] left-[135px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg tracking-[0.36px] leading-[31.3px]">
              Feel Free To Contact Us
            </p>
            <div className="absolute w-[46px] h-[52px] top-[346px] left-[698px]">
              <div className="relative w-11 h-[52px]">
                <div className="absolute w-11 h-11 top-1 left-0 bg-[#ffc56e1c] rounded-[22px] shadow-shadow-small" />
                <div className="left-[17px] text-[#f8a426] absolute top-0 [font-family:'Poppins',Helvetica] font-semibold text-3xl text-center tracking-[0.60px] leading-[52.2px] whitespace-nowrap">
                  1
                </div>
              </div>
            </div>
            <div className="absolute w-[46px] h-[52px] top-[608px] left-[698px]">
              <div className="relative w-11 h-[52px]">
                <div className="absolute w-11 h-11 top-1 left-0 bg-[#26f83b1c] rounded-[22px] shadow-shadow-small" />
                <div className="left-[13px] text-[#26f83b] absolute top-0 [font-family:'Poppins',Helvetica] font-semibold text-3xl text-center tracking-[0.60px] leading-[52.2px] whitespace-nowrap">
                  2
                </div>
              </div>
            </div>
            <img
              className="absolute w-0.5 h-[186px] top-[412px] left-[719px] z-0"
              alt="Vector"
              src="https://c.animaapp.com/x3n6vTjU/img/vector-4.svg"
            />
            <img
              className="absolute w-0.5 h-[186px] top-[672px] left-[719px] z-0"
              alt="Vector"
              src="https://c.animaapp.com/x3n6vTjU/img/vector-5.svg"
            />
            <div className="absolute w-[615px] h-[86px] top-[350px] left-[778px]">
              <IconMoney className="!-left-px !top-1" />
              <div className="absolute w-[547px] h-[86px] top-0 left-[72px]">
                <div className="absolute z-10 top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                  <a href="mailto:Saravanan@TheBestMiHomes.com">Saravanan@TheBestMiHomes.com</a>
                </div>
                <div className="absolute w-[454px] top-[51px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px]">
                  Our Email Address
                </div>
              </div>
            </div>
            <div className="absolute w-[526px] h-36 top-[607px] left-[131px]">
              <IconBook className="!left-[487px] !top-2.5" />
              <div className="absolute w-[458px] h-36 top-0 left-0">
                <div className="absolute z-10 top-0 left-[67px] [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                  <a href="tel:+12487039272">(248)-703-9272</a>
                </div>
                <p className="absolute w-[454px] top-[60px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-2xl text-right tracking-[0.48px] leading-[41.8px]">
                  Please Call, Leave A Message, Or Shoot Us A Text!
                </p>
              </div>
            </div>
            <div className="absolute w-[607px] h-[127px] top-[870px] left-[698px]">
              <div className="absolute w-[46px] h-[52px] top-0 left-0">
                <div className="relative w-11 h-[52px]">
                  <div className="absolute w-11 h-11 top-1 left-0 bg-[#0dc3ff1c] rounded-[22px] shadow-shadow-small" />
                  <div className="left-[13px] text-[#0dc3ff] absolute top-0 [font-family:'Poppins',Helvetica] font-semibold text-3xl text-center tracking-[0.60px] leading-[52.2px] whitespace-nowrap">
                    3
                  </div>
                </div>
              </div>
              <div className="absolute w-[528px] h-[122px] top-[5px] left-[79px]">
                <div className="absolute w-[458px] h-[122px] top-0 left-[74px]">
                  <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                    Referral
                  </div>
                  <p className="absolute w-[454px] top-[60px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg tracking-[0.36px] leading-[31.3px]">
                    We Welcome Referrals To Our Services And Funding
                  </p>
                </div>
                <IconSell className="!-left-px !top-1.5" />
              </div>
            </div>
            <img
              className="absolute w-[836px] h-[754px] top-[74px] left-0 z-0"
              alt="Rectangle"
              src="https://c.animaapp.com/x3n6vTjU/img/rectangle-16.svg"
            />
            <img
              className="absolute w-[836px] h-[551px] top-[591px] left-0 z-0"
              alt="Rectangle"
              src="https://c.animaapp.com/x3n6vTjU/img/rectangle-17.svg"
            />
            <img
              className="absolute w-[840px] h-[754px] top-[317px] left-[600px] z-0"
              alt="Rectangle"
              src="https://c.animaapp.com/x3n6vTjU/img/rectangle-18.svg"
            />
          </div>
        </div>
        <div id="AboutUs" className="absolute w-[1440px] h-[1400px] top-[1024px] left-0 bg-white">
          <div className="absolute w-[537px] top-[117px] left-[135px] [font-family:'Poppins',Helvetica] font-semibold text-[#343434] text-[57px] tracking-[1.14px] leading-[68.4px]">
            Our Projects
          </div>
          <div className="absolute w-[1305px] h-[1180px] top-[220px] left-[135px]">
            <div className="absolute w-[1305px] h-[1180px] top-0 left-0">
              <p className="absolute w-[570px] top-[142px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-2xl tracking-[0.48px] leading-[41.8px]">
                <span className="tracking-[0.12px]">The projects we work as </span>
                <span className="font-bold tracking-[0.12px]">Joint Venture Funding</span>
                <span className="tracking-[0.12px]"> or one we do are as below</span>
              </p>
              <p className="absolute w-[570px] top-0 left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-2xl tracking-[0.48px] leading-[41.8px]">
                We Provide Funding For Any Type Of Project On Good Terms.
              </p>
              <div className="absolute w-[501px] h-[122px] top-[321px] left-[88px]">
                <p className="absolute w-[497px] top-[60px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg tracking-[0.36px] leading-[31.3px]">
                  We Provide Hard Money For Many Types Of Projects. All With A Good Rate
                  <br />
                  - Short-term Funding
                  <br />
                  - Flip Funding
                  <br />
                  - Transactional Funding
                  <br />
                  - Wholesale/ Wholesalers
                  <br />
                  And more! (PoF provided)
                </p>
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                  Private/ Hard Money Lending
                </div>
              </div>
              <img
                className="absolute w-[720px] h-[904px] top-[227px] left-[585px]"
                alt="Vector"
                src="https://c.animaapp.com/x3n6vTjU/img/vector-1-1.svg"
              />
              <img
                className="absolute w-[918px] h-[1111px] top-[69px] left-[387px]"
                alt="Rectangle"
                src="https://c.animaapp.com/x3n6vTjU/img/rectangle-13.svg"
              />
              <div className="absolute w-[501px] h-[200px] top-[594px] left-[88px]">
                <p className="absolute w-[497px] top-[60px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px]">
                  Buy-redesign With A New Layout - Sell
                  <br />
                  - Buy-major Rework-sell
                  <br />
                  - New Construction 
                  <br />
                  - Buy-update-rent-sell
                </p>
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                  Buy-renovate-sell &amp; Rentals
                </div>
              </div>
              <div className="absolute w-[501px] h-[277px] top-[867px] left-[88px]">
                <p className="absolute w-[497px] top-[60px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg tracking-[0.36px] leading-[31.3px]">
                  We Do More That Just These.
                  <br />
                  <br />
                  we Are Open To New Project Ideas As Well. Examples:
                  <br />
                  <br />
                  - Apartments: 2 Unit Duplex To 28-unit Multi-units
                  <br />
                  - Mixed-units: Stores With Residential
                  <br />
                  - Commercial: Small Store Fronts, Trucking Facility
                </p>
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[28px] tracking-[1.40px] leading-[normal]">
                  Other Projects
                </div>
              </div>
            </div>
            <div className="absolute w-[58px] h-[78px] top-[303px] left-0">
              <div className="relative w-14 h-[78px]">
                <div className="absolute w-[47px] h-[47px] top-0 left-0 bg-[#f8a426] rounded-[23.5px] blur-[26px]" />
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-[#343434] text-[52px] tracking-[2.60px] leading-[normal]">
                  01
                </div>
              </div>
            </div>
            <div className="absolute w-[69px] h-[78px] top-[576px] left-0">
              <div className="relative w-[67px] h-[78px]">
                <div className="absolute w-[47px] h-[47px] top-0 left-0 bg-[#26f83b] rounded-[23.5px] blur-[20px]" />
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-[#343434] text-[52px] tracking-[2.60px] leading-[normal]">
                  02
                </div>
              </div>
            </div>
            <div className="absolute w-[70px] h-[78px] top-[849px] left-0">
              <div className="relative w-[68px] h-[78px]">
                <div className="absolute w-[47px] h-[47px] top-0 left-0 bg-[#26f8b9] rounded-[23.5px] blur-[20px]" />
                <div className="absolute top-0 left-0 [font-family:'Poppins',Helvetica] font-semibold text-[#343434] text-[52px] tracking-[2.60px] leading-[normal]">
                  03
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-[1440px] h-[1024px] top-0 left-0 bg-white">
          <div className="relative w-[1305px] h-[1024px]">
            <div className="absolute w-[1305px] h-[1024px] top-0 left-0">
              <img
                className="absolute w-[701px] h-[896px] top-0 left-0"
                alt="Vector"
                src="https://c.animaapp.com/x3n6vTjU/img/vector-1-2.svg"
              />
              <img
                className="absolute w-[881px] h-[1024px] top-0 left-0"
                alt="Rectangle"
                src="https://c.animaapp.com/x3n6vTjU/img/rectangle-3.svg"
              />
              <p className="absolute w-[562px] top-[468px] left-[720px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px]">
                We Provide Funding For All Types Of Real Estate Projects. Buy &amp; Sell. All At A Good Rate
              </p>
              <a href="#AboutUs">
                <div className="px-[29px] py-4 absolute top-[614px] left-[720px] bg-[#01a6dc] rounded-[7px] overflow-hidden inline-flex items-center">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-semibold text-white text-base tracking-[0.80px] leading-[normal]">
                    Get Started
                  </div>
                </div>
              </a>
              <div className="gap-16 absolute top-[765px] left-[720px] inline-flex items-center">
                <div className="relative w-[108px] h-[75px]">
                  <div className="relative w-28 h-[79px] -top-1">
                    <img
                      className="absolute w-[53px] h-[53px] top-0 left-7"
                      alt="Ellipse"
                      src="https://c.animaapp.com/x3n6vTjU/img/ellipse-6.svg"
                    />
                    <div className="absolute w-28 h-[75px] top-1 left-0">
                      <div className="relative w-[108px] h-[75px]">
                        <div className="absolute top-12 left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg text-center tracking-[0.90px] leading-[normal]">
                          Residential
                        </div>
                        <div className="absolute top-0 left-[43px] [font-family:'Poppins',Helvetica] font-semibold text-black text-[34px] text-center tracking-[1.70px] leading-[normal]">
                          $
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative w-[121px] h-[78px]">
                  <div className="relative w-[125px] h-[82px] -top-1">
                    <img
                      className="absolute w-[53px] h-[53px] top-0 left-[34px]"
                      alt="Ellipse"
                      src="https://c.animaapp.com/x3n6vTjU/img/ellipse-5.svg"
                    />
                    <div className="absolute w-[125px] h-[78px] top-1 left-0">
                      <div className="left-[50px] absolute top-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[34px] text-center tracking-[1.70px] leading-[normal]">
                        $
                      </div>
                      <div className="absolute top-[51px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg text-center tracking-[0.90px] leading-[normal]">
                        Commercial
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative w-[78px] h-[78px]">
                  <div className="relative w-[82px] h-[82px] -top-1">
                    <img
                      className="absolute w-[53px] h-[53px] top-0 left-[13px]"
                      alt="Ellipse"
                      src="https://c.animaapp.com/x3n6vTjU/img/ellipse-4.svg"
                    />
                    <div className="absolute w-[82px] h-[78px] top-1 left-0">
                      <div className="left-[29px] absolute top-0 [font-family:'Poppins',Helvetica] font-semibold text-black text-[34px] text-center tracking-[1.70px] leading-[normal]">
                        $
                      </div>
                      <div className="absolute top-[51px] left-0 [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-lg text-center tracking-[0.90px] leading-[normal]">
                        Funding
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute w-[585px] top-[245px] left-[720px] [font-family:'Poppins',Helvetica] font-bold text-[#343434] text-[68px] tracking-[1.36px] leading-[102px]">
                Real Estate Investments
              </div>
            </div>
            <a href="#Contact">
              <div className="px-[29px] py-4 absolute top-[614px] left-[928px] rounded-[7px] overflow-hidden border-[1.5px] border-solid border-[#01a6dc] inline-flex items-center">
                <div className="relative w-fit mt-[-1.50px] [font-family:'Poppins',Helvetica] font-semibold text-[#01a6dc] text-base tracking-[0.80px] leading-[normal]">
                  Contact Us
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="flex w-[1440px] items-center gap-[500px] px-[139px] py-7 fixed top-0 left-0 bg-[#ffffff80] backdrop-blur-[45px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(45px)_brightness(100%)]">
          <Logo className="!h-[29.13px] !w-[135.48px]" />
          <div className="gap-[58px] relative flex-[0_0_auto] inline-flex items-center">
            <div href="#Menu" className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-medium text-[#01a6dc] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
              <a href="#AboutUs" className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-medium text-[#01a6dc] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
                Menu
              </a>
            </div>
            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
              <a href="#AboutUs" className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
                About Us
              </a>
            </div>
            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
              <a href="#Contact" className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-normal text-[#595959] text-xl tracking-[0.40px] leading-[34.8px] whitespace-nowrap">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
